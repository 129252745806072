<template>
  <div>
    <!--begin::Notice-->
    <div
      class="alert alert-custom alert-white alert-shadow gutter-b"
      role="alert"
    >
      <div class="alert-icon alert-icon-top">
        <span class="svg-icon svg-icon-3x svg-icon-primary mt-4">
          <!--begin::Svg Icon-->
          <inline-svg src="media/svg/icons/Communication/Share.svg" />
          <!--end::Svg Icon-->
        </span>
      </div>
      <div class="alert-text">
        <p>
          Загрузите файл формата .csv с разделителем - точкой с запятой со
          следующими полями:
          <br />
          Фамилия;Имя;Отчество;E-mail (если у пользователя определена роль -
          аудитор или исполнитель);Роль(А - аудитор, И - исполнитель, С -
          сотрудник)
        </p>
      </div>
    </div>
    <!--end::Notice-->

    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Загрузка сотрудников из списка</h3>
            </div>
          </div>
          <div class="card-body">
            {{ message }}
            <b-form-file
              v-if="showUpload"
              v-model="file"
              :state="Boolean(file)"
              placeholder="Выберите или пертащите файл..."
              drop-placeholder="Перетащите файл сюда..."
              accept=".csv"
            ></b-form-file>
            <div class="mt-3">Выбранный файл: {{ file ? file.name : "" }}</div>
          </div>
          <div class="card-footer">
            <div class="d-flex justify-content-between border-top pt-10">
              <div>
                <button
                  @click="upload"
                  class="
                    btn btn-success
                    font-weight-bold
                    text-uppercase
                    px-9
                    py-4
                  "
                  data-wizard-type="action-submit"
                >
                  Загрузить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";

export default {
  name: "org-staff-upload",
  components: {},
  data() {
    return {
      message: "",
      showUpload: true,
      file: null,
      result: []
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },

  created() {},

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Сотрудники" },
      { title: "Загрузка списка" }
    ]);
  },
  destroyed() {},
  methods: {
    upload() {
      if (!this.file) {
        Swal.fire({
          title: "",
          text: "Пожалуйста, выберите файл!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      } else {
        this.showUpload = false;
        var reader = new FileReader();
        reader.addEventListener("loadend", event => {
          var csv = event.target.result;
          this.message += "<p><strong>Сотрудники в файле:</strong><br>";

          var lines = csv.split("\n");

          for (var i = 1; i < lines.length; i++) {
            if (!lines[i]) continue;

            var obj = {};
            var currentline = lines[i].split(";");
            obj["last_name"] = currentline[0];
            obj["first_name"] = currentline[1];
            obj["second_name"] = currentline[2];
            obj["username"] = currentline[3];
            switch (currentline[4]) {
              case "А":
                obj["role"] = "inspector";
                break;
              case "И":
                obj["role"] = "executor";
                break;
              case "С":
                obj["role"] = "staff";
                break;
              case "":
                obj["role"] = "staff";
                break;
            }

            this.result.push(obj);
            this.message +=
              currentline[0] +
              " " +
              currentline[1] +
              " " +
              currentline[2] +
              " " +
              currentline[3] +
              " " +
              currentline[4] +
              "<br>";
          }

          this.message += "</p><p><strong>Отправляем на сервер</strong></p>";
          var date = new Date();
          var data;
          this.result.forEach(employee => {
            //    console.log(employee);
            if (employee.username !== "" && employee.role !== "staff") {
              var pass = this.makepass(8);
              data = {
                username: employee.username,
                password: pass,
                first_name: employee.first_name,
                /*"second_name": form.find('input[name="second_name"]').val(),*/
                last_name: employee.last_name,
                is_active: true,
                date_joined: date,
                groups: ["1"],
                user_permissions: [],
                org_id: this.$store.getters.currentUser.org.id
              };
            } else {
              data = JSON.stringify({
                first_name: employee.first_name,
                /*"second_name": form.find('input[name="second_name"]').val(),*/
                last_name: employee.last_name,
                is_active: false,
                date_joined: date,
                username: "simple",
                password: "simple",
                org_id: this.$store.getters.currentUser.org.id
              });
            }
            //  console.log(data);
            ApiService.post("api/v1/users/", data)
              .then(response => {
                if (typeof response.non_field_errors !== "undefined") {
                  this.message +=
                    "<p>Загрузка: " +
                    employee.last_name +
                    " " +
                    employee.first_name +
                    " " +
                    employee.second_name +
                    ' <strong class="text-danger">Не добавлен</strong></p>';
                } else {
                  this.message +=
                    "<p>Загрузка: " +
                    employee.last_name +
                    " " +
                    employee.first_name +
                    " " +
                    employee.second_name +
                    ' <strong class="text-success">Добавлен</strong></p>';
                }
              })
              .catch(error => {
                this.message +=
                  "<p>Загрузка: " +
                  employee.last_name +
                  " " +
                  employee.first_name +
                  " " +
                  employee.second_name +
                  ` <strong class="text-danger">Не добавлен ${error}</strong></p>`;
              });
          });
        });
        reader.readAsText(this.file);
        Swal.fire({
          title: "",
          text: "Сотрудники загружены!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        });
        this.$router.push("/org/staff");
      }
    },
    makepass(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()[]";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    }
  }
};
</script>
